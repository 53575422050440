<template lang="html">
    <div class="component-wrapper d-flex justify-content-center">
        <div class="login-div">
            <h2>Login</h2>
            <b-form method="post">
                <b-input autofocus v-model="username" class="mb-2"
                         placeholder="Username">
                </b-input>
                <b-input v-model="password" class="mb-2"
                         placeholder="Password"
                         type="password">
                </b-input>
                <p class="login-error mb-2" v-if="showLoginError">Wrong login credentials. Please try again.</p>
                <div class="login-buttons d-flex">
                    <button id="login-button" class="mr-4" @click.prevent="authenticate">
                        Login
                    </button>
                    <button id="cancel-login-button" @click.prevent="$emit('close-login')">
                        Cancel
                    </button>
                </div>
            </b-form>
            <p class="mt-2">Don't have an account? <a id="register-href" href="/register"> Register</a> instead.</p>
            <p class="mt-2">Forgot your password? <a id="register-href" href="/forgot-password"> Click here!</a></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            username: '',
            password: '',
            showLoginError: false,
            showSelf: true
        }
    },
    mounted() {
        // this.refreshToken()
    },
    methods: {
        close() {
            // this.$destroy();
        },
        authenticate() {
            const payload = {
                username: this.username,
                password: this.password,
            }
            this.$http.post(this.$store.state.endpoints.obtainJWT, payload).then((response) => {
                this.$store.commit('updateToken', response.data.token);
                // get and set auth user
                const base = {
                    baseURL: this.$store.state.endpoints.baseUrl,
                    headers: {
                        // Set your Authorization to 'JWT', not Bearer!!!
                        Authorization: `JWT ${this.$store.state.jwt}`,
                        'Content-Type': 'application/json',
                    },
                    xhrFields: {
                        withCredentials: true,
                    },
                }
                // Even though the authentication returned a user object that can be
                // decoded, we fetch it again. This way we aren't super dependant on
                // JWT and can plug in something else.
                const axiosInstance = this.$http.create(base);
                axiosInstance({
                    url: `/users/?user__username=${this.username}`,
                    method: 'get',
                    params: {},
                }).then((response) => {
                    if (response.status === 200) {
                        this.$emit('close-login')
                        this.$store.commit('setAuthUser', {
                            authUser: response.data,
                            isAuthenticated: true,
                            username: response.data.results[0].username
                        })
                        if (this.$route.name === 'ResetPassword') {
                            this.$router.push({
                                name: 'HomeRoute',
                                path: '/',
                            }).catch(err => {return err})
                        }
                    }
                })
            })
            .catch((error) => {
                if (error.response.data.non_field_errors[0] === 'Unable to log in with provided credentials.') {
                    this.showLoginError = true
                }
            })
        }
    }
}
</script>

<style lang="css" scoped>
    .login-div {
        z-index: 10;
        position: absolute;
        background: #ffffff;
        padding: 2em;
        border-radius: 1em;
        width: 25%;
        box-shadow: 3px 3px 3px 5px var(--darkBackground);
        color: var(--darkBackground);
    }
    @media (max-width: 1199px) {
        .login-div {
            width: 50%;
        }
        #login-button {
            width: 80%;
        }

    }
    @media (max-width: 792px) {
        .login-div {
            width: 100%;
        }
        #login-button {
            width: 80%;
        }

    }
    #login-button {
        background: var(--hoverColor);
        /* box-shadow: 3px 3px 3px #737372,
                            -1px -1px 3px #bbbbbb; */

        box-shadow: var(--globalButtonShadow);
        border-radius: 1em;
        width: 40%;
        height: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        align-self: center;
        padding: 0.5em;
        border: none;
        margin-right: 1em;
    }
    #login-button:active {
        transform: translateY(4px);
    }
    #login-button:hover {
        -webkit-transition-duration: 0.2s; /* Safari */
        transition-duration: 0.2s;
    }
    #cancel-login-button {
        background: white;
        box-shadow: 3px 3px 2px #737372,
                            -1px -1px 2px #bbbbbb;
        border-radius: 1em;
        width: 40%;
        height: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--darkBackground);
        align-self: center;
        padding: 0.5em;
        border: none;
    }
    #cancel-login-button:active {
        transform: translateY(4px);
    }
    #cancel-login-button:hover {
        -webkit-transition-duration: 0.2s; /* Safari */
        transition-duration: 0.2s;
    }
    .login-error {
        color: red;
        font-weight: bold;
    }
    #register-href {
        color: var(--background);
    }
</style>
