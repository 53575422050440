import Vue from 'vue'
import VueRouter from 'vue-router'
// import AboutView from '@/views/AboutView.vue'
// import PodcastView from '@/views/PodcastView.vue'
// import HomeView from '@/views/HomeView.vue'
// import SearchResultsView from '@/views/SearchResultsView.vue'
// import PopularView from '@/views/PopularView.vue'
// import RandomView from '@/views/RandomView.vue'
// import ProfileView from '@/views/ProfileView.vue'
// import RegisterView from '@/views/RegisterView.vue'
// import MultiplePlaylistsView from '@/views/MultiplePlaylistsView.vue'
// import SinglePlaylistView from '@/views/SinglePlaylistView.vue'
// import CreatePlaylistView from '@/views/CreatePlaylistView.vue'
// import ForgottenPassword from '@/views/ForgottenPassword.vue'
// import ResetPassword from '@/views/ResetPassword.vue'
// import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue'
// import TermsAndConditionsView from '@/views/TermsAndConditionsView.vue'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomeRoute',
        component: () => import('@/views/HomeView.vue')
    },
    {
        path: '/about',
        name: 'AboutRoute',
        component: () => import('@/views/AboutView.vue')
    }, {
        path: '/podcast/:podcastid',
        name: 'PodcastRoute',
        component: () => import('@/views/PodcastView.vue')
    },
    {
        path: '/results/:search',
        name: 'searchResultsRoute',
        component: () => import('@/views/SearchResultsView.vue'),
        props: true,
        meta: {
            title: 'searchResults'
        }
    },
    {
        path: '/popular',
        name: 'PopularRoute',
        component: () => import('@/views/PopularView.vue'),
    },
    {
        path: '/random',
        name: 'RandomRoute',
        component: () => import('@/views/RandomView.vue'),
    },
    {
        path: '/profile/:profilename',
        name: 'ProfileRoute',
        component: () => import('@/views/ProfileView.vue')
    },
    {
        path: '/register',
        name: 'RegisterRoute',
        component: () => import('@/views/RegisterView.vue')
    },
    {
        path: '/collections',
        name: 'PlaylistRoute',
        component: () => import('@/views/MultiplePlaylistsView.vue')
    },
    {
        path: '/collections/:playlistname',
        name: 'SinglePlaylistRoute',
        component: () => import('@/views/SinglePlaylistView.vue')
    },
    {
        path: '/create-collection',
        name: 'CreatePlaylistRoute',
        component: () => import('@/views/CreatePlaylistView.vue')
    },
    {
        path: '/forgot-password',
        name: 'ForgottenPassword',
        component: () => import('@/views/ForgottenPassword.vue')
    },
    {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        component: () => import('@/views/ResetPassword.vue')
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('@/views/PrivacyPolicyView.vue')
    },
    {
        path: '/terms-conditions',
        name: 'TermsAndConditions',
        component: () => import('@/views/TermsAndConditionsView.vue')
    },
    {
        path: '/suggestions',
        name: 'Suggestions',
        component: () => import('@/views/SuggestionsView.vue')
    },
    {
        path: '/tinder',
        name: 'Tinder',
        component: () => import('@/views/TinderView.vue')
    }
]



const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL, routes
})

export default router
