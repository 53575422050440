<template>
    <html lang="en">
        <div class="audio-drama-app" v-if="dataReady">
            <head>
                <meta charset="utf-8">
                <meta http-equiv="x-ua-compatible" content="ie=edge">
                <title>{{this.$store.state.title}}</title>
                <meta name="description" content="">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap" rel="stylesheet">
                <!-- <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous"> -->
            </head>

            <body>
                <nav class="navbar navbar-light navbar-expand-lg">
                    <div class="container-fluid">
                        <a href="/" id="navbar-home" class="navbar-brand">{{this.$store.state.title}}</a>
                        <a href="/collections" id="navbar-collections" class="navbar-brand navbar-page d-none d-lg-flex">Collections</a>
                        <a v-if="isAuthenticated" :href="`/profile/${this.$store.state.username}`" id="navbar-collections" class="navbar-brand navbar-page d-none d-lg-flex">{{this.$store.state.username}}</a>
                        <p v-else-if="!isAuthenticated" @click="showLogin = !showLogin" class="navbar-page login">Log in</p>
                        <button class="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span class="navbar-tog">☰</span>
                        </button>
                        <div class="collapse justify-content-end navbar-collapse mt-3" id="navbarSupportedContent">
                            <div class="d-flex">
                                <input class="form-control me-2"
                                       type="search"
                                       placeholder="Quick search"
                                       aria-label="Search"
                                       v-model="navBarSearchString"
                                       v-debounce:400ms="searchInNavBar">
                                <!-- <button id="searchButton"
                                            class="btn btn-outline-success"
                                            type="submit">Search
                                    </button> -->
                                <div v-if="navBarSearchString.length > 0 && navBarSearchResults.data" class="dropdown-menu search-dropdown">
                                    <div>
                                        <a :href="`/podcast/${result.podcast_id}`" class="navbar-single-result-container" v-for="result in navBarSearchResults.data.results" :key="result.podcast_id">
                                            <p class="navbar-single-result-name">{{result.name}}</p>
                                            <b-dropdown-divider></b-dropdown-divider>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="mobile d-s-block d-md-none mt-2">
                                <b-dropdown-item v-if="!isAuthenticated" @click="showLogin = !showLogin" class="dropdown-item">
                                    Login
                                </b-dropdown-item>
                                <b-dropdown-item v-if="isAuthenticated" :href="`/profile/${this.$store.state.username}`" class="dropdown-item">
                                    Profile
                                </b-dropdown-item>
                                <!-- <b-dropdown-item :href="`/about/`" class="dropdown-item">
                                    About
                                </b-dropdown-item> -->
                                <!-- <b-dropdown-divider v-if="isAuthenticated"> OK</b-dropdown-divider> -->
                                <b-dropdown-item href="/suggestions/" class="dropdown-item">
                                    Missing podcasts?
                                </b-dropdown-item>
                                <b-dropdown-item v-if="isAuthenticated" @click="$root.$store.commit('logoutAuthUser')" class="dropdown-item">
                                    Log out
                                </b-dropdown-item>
                            </div>
                            <div class="navbar-nav justify-content-end mb-2 mb-lg-0 d-none d-md-block">
                                <b-dropdown class="dropdown-header" id="navbarDropdown" size="lg" text="☰">
                                    <b-dropdown-item v-if="!isAuthenticated" @click="showLogin = !showLogin" class="dropdown-item">
                                        Login
                                    </b-dropdown-item>
                                    <b-dropdown-item v-if="isAuthenticated" :href="`/profile/${this.$store.state.username}`" class="dropdown-item">
                                        Profile
                                    </b-dropdown-item>
                                    <!-- <b-dropdown-item :href="`/about/`" class="dropdown-item">
                                        About
                                    </b-dropdown-item> -->
                                    <b-dropdown-item href="/suggestions/" class="dropdown-item">
                                        Missing podcasts?
                                    </b-dropdown-item>
                                    <b-dropdown-divider v-if="isAuthenticated"></b-dropdown-divider>
                                    <b-dropdown-item v-if="isAuthenticated" @click="$root.$store.commit('logoutAuthUser')" class="dropdown-item">
                                        Log out
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                    </div>
                </nav>
                <main role="main" class="container-fluid">
                    <div class="random-podcasts-small d-xl-none d-flex row" v-if="this.$route.name == 'HomeRoute'">
                        <div class="home-header-one-container small">
                            <h1 class="home-header-one">Find your new favorite podcasts</h1>
                        </div>
                        <p style="color:white;">Discover new podcasts</p>
                        <div class="row flex-nowrap small-random-podcasts mb-4 mt-2">
                                <a v-if="randomPodcastOne" id="risingPodcastOne" class="rising-podcast-small col-4 small-random-podcast-col" :href="'/podcast/' + randomPodcastOne.podcast_id">
                                    <b-img fluid rounded :src="require(`@/assets/img/${randomPodcastOne.podcast_id}.jpg`)" alt="" class="rising-podcast-image-small">
                                    </b-img>
                                    <p class="rising-podcast-header-small">{{randomPodcastOne.name}}</p>
                                </a>
                                <a v-if="randomPodcastTwo" id="risingPodcastTwo" class="rising-podcast-small col-4 small-random-podcast-col" :href="'/podcast/' + randomPodcastTwo.podcast_id">
                                    <b-img fluid rounded :src="require(`@/assets/img/${randomPodcastTwo.podcast_id}.jpg`)" alt="" class="rising-podcast-image-small">
                                    </b-img>
                                    <p class="rising-podcast-header-small">{{randomPodcastTwo.name}}</p>

                                </a>
                                <a v-if="randomPodcastThree" id="risingPodcastThree" class="rising-podcast-small col-4 small-random-podcast-col" :href="'/podcast/' + randomPodcastThree.podcast_id">
                                    <b-img fluid rounded :src="require(`@/assets/img/${randomPodcastThree.podcast_id}.jpg`)" alt="" class="rising-podcast-image-small">
                                    </b-img>
                                    <p class="rising-podcast-header-small">{{randomPodcastThree.name}}</p>
                                </a>
                        </div>
                    </div>

                    <div id="home-container">
                        <div class="row flex-nowrap">
                            <div class="col-xl-9">
                                <div v-if="showLogin">
                                    <Login @close-login="showLogin = false">
                                    </Login>
                                </div>
                                <router-view :key="$route.fullPath"></router-view>
                            </div>
                            <div v-if="dataReady" id="right-column" class="col-3 d-none d-xl-block">
                                <h1 id="random-podcast-header" class="d-flex justify-content-center">Discover</h1>
                                <div v-if="randomPodcastOne.podcast_id && randomPodcastTwo.podcast_id && randomPodcastThree.podcast_id" class="rising-podcasts">
                                    <a v-if="randomPodcastOne" id="risingPodcastOne" class="rising-podcast" :href="'/podcast/' + randomPodcastOne.podcast_id">
                                        <p class="rising-podcast-header">{{randomPodcastOne.name}}</p>
                                        <b-img fluid rounded :src="require(`@/assets/img/${randomPodcastOne.podcast_id}.jpg`)" alt="" class="rising-podcast-image">
                                        </b-img>

                                    </a>
                                    <a v-if="randomPodcastTwo" id="risingPodcastTwo" class="rising-podcast" :href="'/podcast/' + randomPodcastTwo.podcast_id">
                                        <p class="rising-podcast-header">{{randomPodcastTwo.name}}</p>
                                        <b-img fluid rounded :src="require(`@/assets/img/${randomPodcastTwo.podcast_id}.jpg`)" alt="" class="rising-podcast-image">
                                        </b-img>

                                    </a>
                                    <a v-if="randomPodcastThree" id="risingPodcastThree" class="rising-podcast" :href="'/podcast/' + randomPodcastThree.podcast_id">
                                        <p class="rising-podcast-header">{{randomPodcastThree.name}}</p>
                                        <b-img fluid rounded :src="require(`@/assets/img/${randomPodcastThree.podcast_id}.jpg`)" alt="" class="rising-podcast-image">
                                        </b-img>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <b-modal centered
                         hide-backdrop
                         hide-header
                         ok-only
                         ok-title="Got it!"
                         v-model="showCookiesModal"
                         ref="cookies-modal"
                         id="cookies-modal"
                         @ok="acceptCookies"
                         @hide="acceptCookies">
                    <p id="cookies-header">Cookies</p>
                    <hr class="mt-0 mb-3">
                    <p>This webpage only uses essential cookies, in order for it to function properly.
                       By closing this window, or continuing navigation, you accept the use of cookies.</p>
                </b-modal>
            </body>
            <!-- <div class="d-lg-flex d-xl-none bottom-small row g-0">
                <div class="col-4 d-flex bottom-small-button justify-content-center">
                    <a href="/">
                        <img fluid rounded :src="require(`@/assets/img/home.png`)" class="bottom-small-image">
                    </a>
                </div>
                <div v-if="isAuthenticated" class="col-4 d-flex bottom-small-button justify-content-center">
                    <a :href="`/profile/${this.$store.state.username}`">
                        <img fluid rounded :src="require(`@/assets/img/profile.png`)" class="bottom-small-image">
                    </a>
                </div>
                <div v-else-if="!isAuthenticated" class="col-4 d-flex bottom-small-button justify-content-center">
                    <img @click="showLogin = !showLogin" fluid rounded :src="require(`@/assets/img/profile.png`)" class="bottom-small-image">
                </div>
                <div class="col-4 d-flex bottom-small-button justify-content-center">
                    <a href="/collections">
                        <img fluid rounded :src="require(`@/assets/img/collections.png`)" class="bottom-small-image">
                    </a>
                </div>
            </div> -->
        </div>
    </html>
</template>


<script>
import Login from '@/components/auth/Login.vue'
export default {
    name: 'App',
    components: {
        Login
    },
    beforeCreate() {
        document.title = 'PodioDramas'
    },
    data() {
        return {
            randomPodcastOne: {},
            randomPodcastTwo: {},
            randomPodcastThree: {},
            randomPodcastIds: [],
            navBarSearchResults: {},
            navBarSearchString: '',
            timeout: null,
            showLogin: false,
            username: '',
            randomPodcasts: [],
            randomPodcastFour: {},
        }
    },
    computed: {
        isAuthenticated() {
            return this.$store.state.isAuthenticated
        },
        dataReady() {
            return this.randomPodcastOne.name && this.randomPodcastTwo.name && this.randomPodcastThree.name
        },
        showCookiesModal: {
            get() {
                if (localStorage.cookiesAccepted === 'true') {
                    return false
                } else {
                    return true
                }
            },
            set() {
                return 0
            }
        }
    },
    mounted() {
        this.$store.commit('inspectToken')
        this.username = this.$store.state.username
        this.getRandomPodcast(0)
    },
    methods: {
        acceptCookies() {
            localStorage.setItem('cookiesAccepted', 'true')
        },
        getRandomPodcast(step) {
            let genres = [
                'comedy_score__gte=',
                'crime_score__gte=',
                'fantasy_score__gte=',
                'folklore_score__gte=',
                'horror_score__gte=',
                'mystery_score__gte=',
                'sci_fi_score__gte=',
                'thriller_score__gte=',
            ].sort(() => 0.5 - Math.random())
            let randomGenreOne = genres[0]
            let randomGenreTwo = genres[1]
            let randomScoreOne = this.randomInt(5, 16).toString()
            let randomScoreTwo = this.randomInt(5, 16).toString()
            let randomQuery = `${this.$store.state.endpoints.baseUrl}small-podcasts?limit=10&${randomGenreOne+randomScoreOne}&${randomGenreTwo+randomScoreTwo}`
            this.$http.get(randomQuery).then((response) => {
                let randomPodcast = response.data.results[response.data.results.length - 1]
                if (step === 0) {
                    this.randomPodcastOne = randomPodcast
                    return this.getRandomPodcast(step=1)
                } else if (step === 1) {
                    if (randomPodcast.podcast_id !== this.randomPodcastOne.podcast_id) {
                        this.randomPodcastTwo = randomPodcast
                        return this.getRandomPodcast(step=2)
                    } else if (randomPodcast.podcast_id === this.randomPodcastOne.podcast_id) {
                        return this.getRandomPodcast(step=1)
                    }
                } else if (step === 2) {
                    if ((randomPodcast.podcast_id !== this.randomPodcastOne.podcast_id) && (randomPodcast.podcast_id !== this.randomPodcastTwo.podcast_id)) {
                        this.randomPodcastThree = randomPodcast
                    } else {
                        return this.getRandomPodcast(step=2)
                    }
                }
            })
        },
        randomInt(min, max) {
            return Math.floor(Math.random() * (max - min) + min)
        },
        getPodcastById(podcastId) {
            this.$http.get(`${this.$store.state.endpoints.baseUrl}podcasts/${podcastId}`).then((response) => {
                return response.data
            })
        },
        getImage(podcastId) {
            return require(`@/assets/img/${podcastId}.jpg`)
        },
        searchInNavBar() {
            let queryUrl = `${this.$store.state.endpoints.baseUrl}podcasts/?name__contains=${this.navBarSearchString}`
            this.$http.get(queryUrl).then((response) => {
                this.navBarSearchResults = response
            })
        }
    },
}
</script>

<style>
:root {
    --background: rgba(67, 99, 168, 1);
    --darkBackground: rgba(60, 75, 128, 1);
    --hoverColor: rgb(75, 111, 190);
    --lightBackground: #6f7be6;
    /* --background: #9743a8;
    --darkBackground: #6c3c80;
    --hoverColor: #994bbe;
    --lightBackground: #d86fe6; */
    --globalButtonShadow: 3px 3px 2px var(--darkBackground),
        -1px -1px 3px var(--hoverColor);
    --globalInwardShadow: inset 2px 2px 10px 0 rgba(68, 80, 97, 0.8),
                inset -2px -2px 10px 0 rgba(92, 137, 203, 0.5);
    --mainFont: 'Josefin Sans';
}

p {
    margin-bottom: 0px !important;
}

body {
    background: linear-gradient(to bottom, var(--darkBackground), var(--background));
    min-height: 100vh;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
}


.navbar-brand:hover {
    color: white !important;
    text: white;
    cursor: default;
}

.navbar-brand:active {
    color: white !important;
}

.navbar .container-fluid a {
    color: white;
}

.nav-link {
    color: white !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    background: var(--lightBackground);
}

.navbar .container-fluid a:hover {
    background: var(--lightBackground);
}

.dropdown-item:hover {
    background: var(--background) !important;
    cursor: pointer;
}

.dropdown-item:active {
    background: var(--background) !important;
    cursor: pointer;
}

.small-random-podcasts {
    gap: 0.4em;
}

.random-podcasts-small {
    display: flex;
    justify-content: center;
}

.dropdown-item a {
    background: none !important;
    width: 100%;
    border-radius: 0.2em;
}

.dropdown-item a:hover {
    background: var(--background) !important;
}

.dropdown-item a:active {
    background: var(--background) !important;
}

.navbar {
    margin-top: 0px !important;
    padding: 0px !important;
}

#searchButton {
    color: white;
    background: var(--darkBackground);
    border: none;
}

#searchButton:hover {
    background: var(--background);
}

@media (max-width: 1199px) {
    #home-container {
        margin-left: 0em;
    }
}

@media (min-width: 1200px) {
    #home-container {
        margin-left: 5em;
        margin-top: 0em;
    }
}

#home-container {
    position: relative;
}

.rising-podcasts {
    display: inline-block;
    text-align: center;
}

.rising-podcast {
    display: flex;
    height: 100%;
    width: 66%;
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    padding: 1vh;
    color: white;
    font-weight: bolder;
    text-decoration: none;
    box-shadow: var(--globalButtonShadow);
    margin-bottom: 1em;
    position: sticky;
}

.small-random-podcast-col {
    box-shadow: var(--globalButtonShadow);
    border-radius: 20px;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 0.4em;
    max-width: 32% !important;
    cursor: pointer;
    text-decoration: none;
    color: white;
}

.small-random-podcast-col:hover {
    color: white;
    background: var(--hoverColor);
    -webkit-transition-duration: 0.2s; /* Safari */
    transition-duration: 0.2s;
    cursor: pointer;
    color: white;
}

.rising-podcast:hover {
    background: var(--hoverColor);
    -webkit-transition-duration: 0.2s; /* Safari */
    transition-duration: 0.2s;
    cursor: pointer;
    color: white;
}


.rising-podcast[title]:hover:after {}

.rising-podcast-image {
    height: 17vh;
    width: 17vh;
    margin-bottom: 1em;
}

.rising-podcast-image-small {
    height: 6vh !important;
    width: auto;
}



.rising-podcast-header-small {
    font-size: 1em;
    word-wrap: break-word;
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.btn.dropdown-toggle {
    background: var(--darkBackground) !important;
    border: none !important;
    outline: 0 !important;
}

.btn.dropdown-toggle:hover,
.btn.dropdown-toggle:active,
.btn.dropdown-toggle:focus {
    background: var(--background) !important;
    border: none !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0 !important;
}

#random-podcast-header {
    color: white;
}

#havbar-home {
    cursor: pointer;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    margin-top: 60px;
}

.dropdown-menu.search-dropdown {
    display: block;
    max-width: 20%;
    color: #333333;
    right: auto;
    left: auto;
}

@media (max-width: 991px) {
    .dropdown-menu.search-dropdown {
        top: 103px;
    }
}

@media (min-width: 992px) {
    .dropdown-menu.search-dropdown {
        top: 75px;
    }
}

.navbar-single-result-name {
    cursor: pointer;
    text-decoration: none !important;
    color: #333333 !important;
    padding: 0.5em;
    height: 100%;
    width: 100%;
}

.navbar-single-result-name:hover {
    background: var(--hoverColor);
    color: white !important;
    text-decoration:  !important;
}

.navbar-single-result-container {
    text-decoration: none;
}

.login-button {
    height: 100%;
    background: white;
    color: var(--darkBackground) !important;
    padding: 0.4em;
    border-radius: 5px;
    margin-right: 1em;
    text-decoration: none;
}

.login-button:hover {
    background: var(--lightBackground) !important;
    color: white !important;
    cursor: pointer;
}

.dropdown-menu {
    right: 0;
    left: auto;
    border: none !important;
    outline: none !important;
}

#navbarDropdown > ul {
    min-width: fit-content;
}

.navbar-toggler-icon {
    filter: brightness(0) invert(1);
}

.navbar-toggler:focus {
    outline: none;
    box-shadow: none !important;
}

.navbar-light .navbar-toggler {
    border-color: rgba(255, 255, 255, 1) !important;
    border-width: 0px !important;
}

#navbar-home:hover {
    background: none !important;
    cursor: pointer;
    text-decoration: underline;

}

#navbar-collections {
    background: none !important;
    font-size: 1em;
}

#navbar-collections:hover {
    background: none !important;
    cursor: pointer;
    text-decoration: underline;
}

.dropdown-toggle:after {
    content: none !important
}

.navbar-tog {
    color: white;
}

.navbar-page {
    /* border-bottom: 0.2em solid white; */
    color: white;
    padding: 0.4em;
    justify-content: center;
}

.navbar-page:hover {
    cursor: pointer;
    user-select: none;
}
.bottom-small {
    /* min-width: 100% !important; */
    height: 8vh;
    background: var(--background);
    bottom: 0px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    /* box-shadow: 3px 3px 3px 3px white; */
    border-top: 1px solid white;
    padding: 0.2em;

}
.bottom-small-button {
    align-items: center;
    text-align: center;
    color: white;
}
.bottom-small-image {
    height: 2em;
    width: auto;
    cursor: pointer;
}
.navbar-page.login {
    cursor: pointer;
}

.navbar-page.login:hover {
    text-decoration: underline;
}
.close-login {
    background: red;
    height: 1em;
    width: 1em;
}
#right-column {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}
label.custom-control-label {
    margin-left: 0.4em;
}
.home-header-one-container.small {
    padding: 0.8em;
    margin-bottom: 1em;
    background: white;
    color: var(--darkBackground);
    display: flex;
    justify-content: center;
    margin-top: 0.4em;
}
.home-header-one {
    font-size: 1.2em;
    margin-bottom: 0px;
}
#cookies-modal___BV_modal_content_ {
    margin-top: auto !important;
    box-shadow: var(--globalButtonShadow);
}
.modal-footer {
    border: none !important;
}
#cookies-modal___BV_modal_footer_ > button {
    background: var(--hoverColor);
    box-shadow: 1px 1px 1px var(--darkBackground),
        -1px -1px 1px var(--hoverColor);
        border: none;
}

#cookies-modal___BV_modal_footer_ {
    padding: none;
    padding-bottom: 0.75rem;
}

#cookies-modal___BV_modal_body_ {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0rem;
}

#cookies-header {
    font-size: 1.5em;
    font-weight: bold;
}
#cookies-modal___BV_modal_content_ {
    color: var(--darkBackground) !important;
    padding-left: 1em;
    padding-right: 1em;
}
</style>
