import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import {BootstrapVue} from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import VueApexCharts from 'vue-apexcharts'
import titleMixin from './mixins/titleMixin'
import jwt_decode from 'jwt-decode'
Vue.use(BootstrapVue)
Vue.use(Vuex)
Vue.use(VueApexCharts)
Vue.mixin(titleMixin)
Vue.component('apexchart', VueApexCharts)
import vueDebounce from 'vue-debounce'

Vue.use(vueDebounce)

const base = axios.create({baseURL: `${process.env.VUE_APP_BASE_URL}/api`})
axios.defaults.withCredentials = true

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        title: 'PodioDramas',
        searchResults: {},
        searchQuery: '',
        jwt: localStorage.getItem('token'),
        registrationUrl: `${process.env.VUE_APP_BASE_URL}/rest-auth/registration/`,
        endpoints: {
          obtainJWT: `${process.env.VUE_APP_BASE_URL}/auth/obtain_token/`,
          refreshJWT: `${process.env.VUE_APP_BASE_URL}/auth/refresh_token/`,
          verifyJWT: `${process.env.VUE_APP_BASE_URL}/auth/verify_token/`,
          baseUrl: `${process.env.VUE_APP_BASE_URL}/api/`,
          baseAuthUrl: `${process.env.VUE_APP_BASE_URL}/auth/`,
      },
        authUser: {},
        isAuthenticated: false,
        username: ''
    },
    mutations: {
        addSearchData: (state, data) => {
            state.searchResults = Object.assign({}, data)
        },
        addSearchQuery: (state, data) => {
            state.searchQuery = data
        },
        updateToken(state, newToken){
            localStorage.setItem('token', newToken)
            state.jwt = newToken
        },
        removeToken(state){
            localStorage.removeItem('token')
            state.jwt = null
        },
        setAuthUser(state, { authUser, isAuthenticated, username }) {
            Vue.set(state, "authUser", authUser)
            Vue.set(state, "isAuthenticated", isAuthenticated)
            Vue.set(state, 'username', username)
        },
        logoutAuthUser(state) {
            Vue.set(state, 'authUser', null)
            Vue.set(state, 'isAuthenticated', false)
            Vue.set(state, 'username', '')
            this.commit('removeToken')
        },
        inspectToken() {
            const token = this.state.jwt
            if (token) {
                const decoded = jwt_decode(token)
                const expirationTime = decoded.exp
                const originatedAt = decoded.orig_iat
                const sevenDays = 604800
                const thirtyMinutes = 1800
                const nowInSecs = Date.now() / 1000
                const isExpiringIn30Min = (expirationTime - nowInSecs) < thirtyMinutes
                const isValid = nowInSecs < expirationTime
                // const sevenDays = 90
                // const thirtyMinutes = 30
                if (nowInSecs > expirationTime) {
                    this.commit('removeToken')
                    this.commit('logoutAuthUser')
                } else if (isExpiringIn30Min && nowInSecs < originatedAt + sevenDays) {
                    // IF TOKEN EXPIRE IN LESS THAN 30MN BUT STILL IN REFRESH PERIOD THEN REFRESH
                    this.commit('refreshToken')
                } else if (isValid && !this.state.isAuthenticated) {
                    this.commit('setAuthUser', {authUser: this.state.authUser, isAuthenticated: true, username: this.state.username})
                }


            }
        },
        refreshToken() {
            const payload = {
                token: this.state.jwt
            }
            axios.post(this.state.endpoints.refreshJWT, payload).then((response) => {
                this.commit('updateToken', response.data.token)
            }).catch((error) => {
                console.log(error)
            })
        },
    },
    // actions: {
    //     obtainToken(context, credentials) {
    //         const payload = {
    //             username: credentials['username'],
    //             password: credentials['password']
    //         }
    //         axios.post(this.state.endpoints.obtainJWT, payload).then((response) => {
    //             this.commit('updateToken', response.data.token)
    //         }).catch((error) => {
    //             console.log(error)
    //         })
    //     },


    // }
})


Vue.prototype.$http = base
Vue.use(VueAxios, axios)
Vue.config.productionTip = false
new Vue({
    router,
    render: (h) => h(App),
    store
}).$mount('#app')
